<template>
  <DetailBox
    :info="detailInfo"
    :title="详情"
    :show-box="detailBox.showDetail"
    :show-button="detailBox.showButton"
    :img-button-visible="imgButtonVisible"
    :judge-selected="judgeSelected"
    :history-button-visible="historyButtonVisible"
    @on-click-button="updateSelectedEarthquake(detailBox.quickEarthquakeId)"
    @on-close="detailBox.showDetail = false"
  />

  <div class="buttonClass">
    <el-popover
      ref="controlVisible"
      v-model:visible="layerControl.visible"
      placement="top"
      trigger="click"
      width="170px"
    >
      <template #reference>
        <el-button
          style="margin: 5px"
          icon="el-icon-view"
          :size="inputSize"
          type="primary"
          plain
          @click="
            layerVisible = !layerVisible;
            closeIndex('');
          "
        >
          图层控制
        </el-button>
      </template>
      <el-row align="middle">
        选择图层：
        <el-select
          v-model="imageryConfig.mapStyle"
          style="width: 200px"
          :size="inputSize"
          placeholder="请选择"
        >
          <el-option
            v-for="item in imageryConfig.options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-row>
      <hr />
      <el-row type="flex" justify="space-between">
        <el-checkbox v-model="layerControl.showEpicenter" label="震源" />
      </el-row>
      <el-row type="flex" justify="space-between">
        <div>
          <el-tooltip placement="top">
            <template #content> </template>
            <i class="el-icon-info"></i>
          </el-tooltip>
          <el-checkbox v-model="layerControl.showFireCenter" label="救援力量" />
        </div>
        <div>
          <el-tooltip placement="top">
            <template #content> 地图上面的边界线 </template>
            <i class="el-icon-info"></i>
          </el-tooltip>
          <el-checkbox v-model="linedataVisible"> 边界线 </el-checkbox>
        </div>
      </el-row>
    </el-popover>
    <el-button
      type="primary"
      plain
      :size="inputSize"
      style="margin: 5px"
      icon="el-icon-document-add"
      @click="opendiag()"
      @close="closediag()"
    >
      数据
    </el-button>

    <el-popover :width="400" placement="top" trigger="hover">
      <template #reference>
        <el-button
          style="margin: 5px"
          icon="el-icon-s-custom"
          :size="inputSize"
          type="primary"
          plain
          @click="
            rescueVisible = true;
            closeIndex('');
          "
        >
          救援力量
        </el-button>
      </template>
      <div>
        救援力量展示总量:
        <el-input-number
          v-model="rescueForceDistribution"
          :step="5"
          :size="inputSize"
          style="margin-top: 10px"
          :min="5"
          :max="100000"
        />
        <el-button
          type="primary"
          plain
          icon="el-icon-success"
          :size="inputSize"
          style="margin-top: 10px; margin-left: 20px"
          @click="
            reGetCalculateWeight();
            rescueVisible = true;
          "
        >
          一键显示
        </el-button>
      </div>
    </el-popover>
  </div>
  <el-row class="toolbar">
    <el-dialog
      v-model="diagVisible1"
      title="请上传相应的Excel格式文件："
      width="50%"
      :before-close="afterDialogClose"
    >
      <div class="upload-container">
        <el-upload
          ref="upload"
          v-loading="loading"
          drag
          :action="' '"
          :element-loading-text="loadingText"
          :file-list="fileList"
          :on-change="onChange"
          :on-progress="onProgress"
          :on-success="onSuccess"
          :before-upload="beforeUpload"
          :auto-upload="false"
        >
          <el-icon class="el-icon--upload">
            <upload-filled />
          </el-icon>
          <div class="el-upload__text">
            Drop file here or <em>click to upload</em>
          </div>
          <template #tip>
            <div style="text-align: center">
              <p>Please upload Excel spreadsheet data</p>
              <p class="text-blue"></p>
            </div>
          </template>
        </el-upload>
      </div>
    </el-dialog>
    <EarthquakeSelect
      ref="select"
      :selected-earthquake-id="selectedEarthquakeInfo.earthquakeId"
      @change-select="updateSelectedEarthquake"
      @update-list="updateEarthquakePositionInfoList"
      @jump-to="cameraTo"
    />
    <EstimateEarthquake ref="estimate" :earthquake="selectedEarthquakeInfo" />
    <Predict ref="predict" />
    <AddEarthquake ref="add" />
    <ImgDisplay ref="img" />
    <SeismicZones ref="SeismicZones" />
  </el-row>
  <!--    cesium代码-->
  <el-row
    ref="viewerContainer"
    v-loading="cesiumLoading"
    class="viewer"
    element-loading-text="初次加载可能较慢，请耐心等待"
  >
    <vc-viewer
      ref="vcViewer"
      fill="green"
      :animation="viewerConfig.animation"
      :base-layer-picker="viewerConfig.baseLayerPicker"
      :timeline="viewerConfig.timeline"
      :fullscreen-button="viewerConfig.fullscreenButton"
      :fullscreen-element="viewerConfig.fullscreenElement"
      :info-box="viewerConfig.infoBox"
      :selection-indicator="false"
      :show-credit="viewerConfig.showCredit"
      @ready="onViewerReady"
    >
      <vc-primitive-tileset
        ref="primitive"
        url="https://file.zouran.top/smart3d_las/tileset.json"
        :show="layerControl.showModel"
        @ready-promise="onReadyPromise"
        @click="onClicked"
      />

      <!-- 边界线 -->
      <vc-datasource-geojson
        ref="ds"
        data="./china.json"
        :show="linedataVisible"
        :fill="options.fill"
        :stroke="options.stroke"
      />
      <!-- 测绘工具 -->
      <vc-selection-indicator ref="selectionIndicator" @pick-evt="pickEvt" />
      <vc-measurements
        ref="measurementsRef"
        position="bottom-left"
        :main-fab-opts="measurementFabOptions"
        @draw-evt="drawEvt"
        @active-evt="activeEvt"
        @editor-evt="editorEvt"
        @mouse-evt="mouseEvt"
      />
      <!--      地图渲染震源 -->
      <vc-datasource-custom name="epicenter" :show="layerControl.showEpicenter">
        <vc-entity
          v-for="(item, index) in earthquakePositionInfoList"
          :id="'epicenter_' + item.earthquakeId.toString() + '_' + uuid()"
          :key="index"
          :position="[item.longitude, item.latitude, 0]"
          description="epicenter"
        >
          <!-- <vc-graphics-point ></vc-graphics-point> -->
          <vc-graphics-point
            :color="pointcolor(item)"
            :pixel-size="1.5 * item.magnitude"
          />
        </vc-entity>
      </vc-datasource-custom>
      <vc-layer-imagery
        :alpha="imageryConfig.alpha"
        :brightness="imageryConfig.brightness"
        :contrast="imageryConfig.contrast"
        :sort-order="10"
      >
        <vc-imagery-provider-tianditu
          ref="provider"
          :map-style="imageryConfig.mapStyle"
          token="de232c2bf878c7a7928afde78e339913"
        />
      </vc-layer-imagery>
      <vc-navigation
        :offset="navigationConfig.offset"
        :other-opts="navigationConfig.otherOpts"
      />
      <vc-ajax-bar />
      <!--        hyc：增加椭圆显示位置-->
      <vc-entity
        ref="entity"
        :position="[longTemp, latiTemp]"
        description="您所点击的位置所表示的区域"
      >
        <vc-graphics-ellipse
          :semi-minor-axis="50.0"
          :semi-major-axis="50.0"
          :material="[255, 0, 0, 125]"
        />
        <vc-graphics-label
          text="起点"
          font="20px sans-serif"
          :pixel-offset="[0, 20]"
          fill-color="red"
        />
      </vc-entity>
      <!--hyc:消防队位置-->
      <vc-datasource-custom
        :show="layerControl.showFireCenter"
        name="fireCenterList"
        :entities="fireCenterBillboards"
        @cluster-event="onFireCenterClusterEvent"
        @ready="onFireCenterReady"
        @mouseout="onMouseout"
        @mouseover="onMouseover"
      />
    </vc-viewer>
  </el-row>
</template>

<script>
import {
  getAllNearHospitalPositionByEarthquakeId,
  getHospitalByHospitalId,
  getDetailEarthquakeInfoById,
  initEarthquakePositionInfo,
  getEarthquakeInfoById,
  getNearFireCenterWeightByEarthquakeId,
  getNearestHospitalByPosition,
  restapi,
} from "@/axios/api/CesiumApi";
import { myMessage } from "@/js/utils";
import DetailBox from "../components/DetailBox";
import EarthquakeSelect from "../components/EarthquakeSelect";
import ImgDisplay from "@/components/ImgDisplay.vue";
import SeismicZones from "@/components/SeismicZones.vue";
import { UploadFilled } from "@element-plus/icons-vue";
import { baseURLs } from "@/axios/httpConfig";
import XLSX from "xlsx";
export default {
  name: "Cesium",
  components: {
    ImgDisplay,
    DetailBox,
    EarthquakeSelect,
  },
  data() {
    return {
      upload: baseURLs.imgApi + "/upload/",
      predict_preview: baseURLs.imgApi + "/predict_preview/",
      predict: baseURLs.imgApi + "/predict/",
      predictVisible: false,
      fileList: [],
      file: "",
      inputSize: "mini",
      //详情传值
      detailInfo: [],
      //历史地震控制
      historyButtonVisible: false,
      //上传数据框控制
      diagVisible1: false,
      maxSize: 10,
      loading: false,
      loadingText: "",
      //救援力量展示框控制
      rescueVisible: false,
      //图片按钮控制
      imgButtonVisible: false,
      //边界线控制
      linedataVisible: false,
      //边界线选项
      options: {
        fill: [0, 0, 0, 0.1],
        stroke: "yellow",
      },
      //救援力量
      rescueForceDistribution: 10,
      //救援力量信息
      rescue: [],

      layerVisible: false,
      activeCalculateWeight: ["1"],
      totalRescueCapacity: 1000,
      cesiumLoading: true,
      fireCenterLoading: false,
      visibleRoad: false,
      radioRoad: 1,
      Search: this.Search,
      Check: this.Check,
      //data
      //  fireWeight: [],
      hospitalBillboards: [],
      fireCenterBillboards: [],
      hospitalList: [],
      //记录列表地震
      earthquakeInfoList: [],
      //earthquakeInfo
      earthquakePositionInfoList: [
        {
          earthquakeId: 1,
          longitude: 99.87,
          latitude: 25.67,
          magnitude: 6.4,
        },
      ],
      //当前地震
      selectedEarthquakeInfo: {
        earthquakeId: 1474,
        earthquakeName: "云南大理州漾濞县",
        magnitude: 6.4,
        highIntensity: 8.308250979049514,
        longitude: 99.87,
        latitude: 25.67,
        depth: 8.0,
        earthquakeTime: "2021-05-21 21:48:34",
        intensityLineList: [
          {
            longRadius: 4.831947699520125,
            shortRadius: 2.1831727770881724,
            angle: 0.6,
            intensity: 8,
            earthquakeId: "1474",
          },
          {
            longRadius: 27.59874969680684,
            shortRadius: 14.267550354961134,
            angle: 0.6,
            intensity: 7,
            earthquakeId: "1474",
          },
          {
            longRadius: 67.17692145364093,
            shortRadius: 40.692466450766986,
            angle: 0.6,
            intensity: 6,
            earthquakeId: "1474",
          },
        ],
      },
      //detailBox
      detailBox: {
        showDetail: false,
        detailClass: 0,
        detailIndex: 0,
        quickEarthquakeId: 0,
        showButton: true,
      },
      //measure
      measurementFabOptions: {
        modelValue: false,
        direction: "right",
      },
      //viewer
      viewerConfig: {
        animation: false,
        timeline: false,
        baseLayerPicker: false,
        fullscreenButton: false,
        infoBox: false,
        showCredit: false,
        fullscreenElement: document.body,
      },
      //imagery-provider
      imageryConfig: {
        alpha: 1,
        brightness: 1,
        contrast: 1,
        mapStyle: "img_w",
        options: [
          {
            value: "img_c",
            label: "影像地图服务(经纬度)",
          },
          {
            value: "img_w",
            label: "影像地图服务(墨卡托)",
          },
          {
            value: "vec_c",
            label: "矢量地图服务(经纬度)",
          },
          {
            value: "vec_w",
            label: "矢量地图服务(墨卡托)",
          },
          {
            value: "ter_c",
            label: "地形晕渲服务(经纬度)",
          },
          {
            value: "ter_w",
            label: "地形晕渲服务(墨卡托)",
          },
          {
            value: "ibo_c",
            label: "境界(经纬度)",
          },
          {
            value: "ibo_w",
            label: "境界(墨卡托)",
          },
        ],
      },
      //navigation
      navigationConfig: {
        offset: [10, 25],
        otherOpts: {
          offset: [0],
          position: "bottom-right",
        },
      },
      //layerControl
      layerControl: {
        visible: false,
        showIntensity: false, //更改
        showEpicenter: true,
        showHospital: false,
        showFireCenter: false,
        showdata: false,
      },

      // hyc2
      num: 0,
      SuccessClick: false,
      startLon: 0.0,
      startLat: 0.0,
      startHei: 0.0,
      longTemp: 0.0,
      latiTemp: 0.0,
      heiTemp: 0.0,
      endLon: 0.0,
      endLat: 0.0,
      endHei: 0.0,
    };
  },
  computed: {
    judgeSelected: function () {
      //不可更改为===
      return (
        this.detailBox.quickEarthquakeId !==
        this.selectedEarthquakeInfo.earthquakeId
      );
    },
  },
  watch: {
    timeline(val) {
      this.navigationConfig.otherOpts.offset = val
        ? [0, 30]
        : this.fullscreenButton
        ? [30, 5]
        : [0, 5];
    },
    fullscreenButton(val) {
      if (!this.timeline && !val) {
        this.navigationConfig.otherOpts.offset = [0, 5];
      } else if (!this.timeline && val) {
        this.navigationConfig.otherOpts.offset = [30, 5];
      }
    },
    detailBox: {
      handler(detailbox) {
        this.imgButtonVisible =
          detailbox.quickEarthquakeId === 1474 && detailbox.detailClass === 1;
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.getEarthquakeList();
  },
  mounted() {},
  methods: {
    afterDialogClose(done) {
      done();
    },
    openNewWindow(val) {},
    onChange(file, fileList) {
      //在这个方法中进行解析
      // this.fileList = [fileList[fileList.length - 1]]; // 只能上传一个Excel，重复上传会覆盖之前的
      this.fileList = fileList;
      this.file = file.raw;
      let reader = new FileReader();
      let _this = this;
      reader.readAsArrayBuffer(this.file);
      const that = this;
      reader.onload = function () {
        let buffer = reader.result;
        let bytes = new Uint8Array(buffer);
        let length = bytes.byteLength;
        let binary = "";
        for (let i = 0; i < length; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        let XLSX = require("xlsx");
        let wb = XLSX.read(binary, {
          type: "binary",
        });
        const outdata = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
        that.rescue = outdata;
      };
    },
    opendiag() {
      this.diagVisible1 = true;
    },
    closediag() {
      this.diagVisible1 = false;
    },
    beforeUpload(file) {
      // console.log(1);
      let sizeIsValid = false,
        formatIsValid = false;
      if (file.size / 1024 / 1024 < 10) sizeIsValid = true;
      else myMessage.elSuccess("上传文件大小不能超过10MB");
      const suffix = file.name.split(".")[1].toLowerCase();
      console.log(suffix);
      if (suffix === "xlsx" || suffix === "xls") {
        formatIsValid = true;
      } else {
        myMessage.elSuccess("上传文件格式错误");
      }
      console.log("beforeupload");
      return sizeIsValid && formatIsValid;
    },
    onProgress(evt) {
      console.log(evt, "onProgress");
    },
    onSuccess(response) {
       this.loadingText = "正在解析您上传的Excel数据";
    },
    uuid() {
      const s = [];
      const hexDigits = "0123456789abcdef";
      for (let i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = "4";
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
      s[8] = s[13] = s[18] = s[23] = "-";

      this.uuidA = s.join("");
      // console.log(this.uuidA);
      return this.uuidA;
    },
    imgDisplayVisible() {
      this.$refs.img.ImgDisplayVisible =
        this.selectedEarthquakeInfo.earthquakeId === 1474;
    },
    closeIndex(val) {
      this.detailBox.showDetail = false;
      this.$refs.add.addEarthquakeVisible = false;
      this.$refs.estimate.dialogVisible = false;
      this.$refs.select.earthquakeSelectVisible = false;
      this.$refs.SeismicZones.SeismicZonesVisible = false;

      if (val === "predict") this.$refs.predict.addEarthquakeVisible = true;
      if (val === "add") this.$refs.add.addEarthquakeVisible = true;
      if (val === "estimate") this.$refs.estimate.dialogVisible = true;
      if (val === "select") this.$refs.select.earthquakeSelectVisible = true;
      if (val === "SeismicZones")
        this.$refs.SeismicZones.SeismicZonesVisible = true;
      console.log(val);
    },
    pointcolor(item) {
      // console.log(item.magnitude)
      if (4 > item.magnitude) return "#efe8e8";
      else if (5 > item.magnitude) return "#b4f19a";
      else if (6 > item.magnitude) return "#f3a201";
      else return "#ff263c";
    },
    reGetCalculateWeight() {
      this.$message.warning("请稍后,后台正在重新展示~");
      //this.fireWeight = [];
      this.fireCenterBillboards = [];
      this.getFireCenters();
    },
    //update earthquake list
    updateEarthquakePositionInfoList(list) {
      this.earthquakePositionInfoList = list;
      for (let i = 0; i < list.length; i++) {
        if (this.selectedEarthquakeInfo.earthquakeId === list[i].earthquakeId)
          return;
      }
      if (list.length === 0) return;
      this.selectedEarthquakeInfo = list[0];
      this.getHospitals();
    },
    //get earthquake list
    getEarthquakeList() {
      let that = this;
      //that.cesiumLoading = false;
      //that.$message.success("正在查询地震数据,请稍后....");
      initEarthquakePositionInfo(5.6).then((response) => {
        that.earthquakePositionInfoList = response;
        that.getHospitals();
        that.cesiumLoading = false;
        // setTimeout(function () {
        //   that.cameraTo(
        //     that.selectedEarthquakeInfo.longitude,
        //     that.selectedEarthquakeInfo.latitude,
        //     100000
        //   );
        // }, 2000);
        //console.log("camera");
        // that.getFireCenters();
      });
    },
    //change selected earthquake by index
    updateSelectedEarthquake(earthquakeId) {
      console.log(earthquakeId);
      const that = this;
      // this.imageryConfig.mapStyle="img_w";
      getEarthquakeInfoById(earthquakeId).then((response) => {
        that.selectedEarthquakeInfo = response;
        that.getHospitals();
        that.detailBox.showDetail = false;
      });
    },
    //change location
    cameraTo(lat, lon, height) {
      this.imageryConfig.mapStyle = "img_w";
      let viewer = this.$refs.vcViewer.getCesiumObject();
      viewer.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(lat, lon, height),
        orientation: {
          heading: Cesium.Math.toRadians(0.0),
          pitch: Cesium.Math.toRadians(-90.0),
          roll: 0.0,
        },
      });
    },
    //event when mouse pick an entity or a position
    pickEvt(e) {
      if (!e) {
        console.log("!e");
        return;
      }
      this.layerControl.visible = false;
      try {
        // console.log('pickEvt',e)
        if (e._id === "__Vc__Pick__Location__") {
          if (this.num !== 0) {
            let cartographic = Cesium.Cartographic.fromCartesian(
              e._position._value
            );
            this.longTemp = Cesium.Math.toDegrees(cartographic.longitude);
            this.latiTemp = Cesium.Math.toDegrees(cartographic.latitude);
            this.heiTemp = cartographic.height;
            this.num = 0;
          } else {
            // this.visibleRoad = false;
          }
        }
        let kind = e.id.split("_")[0];
        let index = parseInt(e.id.split("_")[1]);
        if (kind === "epicenter") {
          const that = this;
          getDetailEarthquakeInfoById(index).then((response) => {
            that.detailInfo = response;
            that.historyButtonVisible = true;
            that.detailBox.showButton = true;
            that.detailBox.showDetail = true;
            that.detailBox.detailClass = 1;
            that.detailBox.detailIndex = index;
            that.detailBox.quickEarthquakeId = index;
          });
        } else if (kind === "hospital") {
          const that = this;
          getHospitalByHospitalId({
            hospitalId: index,
          }).then((response) => {
            that.detailInfo = response;
            that.historyButtonVisible = false;
            that.detailBox.showButton = false;
            that.detailBox.showDetail = true;
            that.detailBox.detailClass = 2;
            that.detailBox.detailIndex = index;
            that.detailBox.quickEarthquakeId = index;
          });
        } else if (kind === "fireCenter") {
          this.historyButtonVisible = false;
          this.detailBox.showButton = false;
          this.detailBox.showDetail = true;
          this.detailBox.detailClass = 3;
          this.detailBox.detailIndex = parseInt(e.id.split("_")[2]);
          console.log(e.id, 555);
          let tmp = [];
          console.log(this.rescue, "rescue");
          console.log(this.detailBox, "detailBox");
          for (let i = 0; i < this.rescue.length; i++) {
            if (this.rescue[i].fireCenterId == this.detailBox.detailIndex) {
              this.detailInfo = [];
              this.detailInfo.push(
                ...[
                  {
                    key: "名称",
                    value: this.rescue[i].fireCenterName,
                  },
                  {
                    key: "经纬度",
                    value: this.rescue[i].firelocation,
                  },
                  {
                    key: "地址",
                    value: this.rescue[i].fireCenterAddress,
                  },
                  {
                    key: "人数",
                    value: this.rescue[i].firefighter + "人",
                  },
                ]
              );
            }
          }
          console.log(this.detailInfo, "detailInfo");
        } else {
          this.detailBox.showDetail = false;
        }
      } catch (err) {
        this.detailBox.showDetail = false;
      }
    },
    onViewerReady(cesiumInstance) {
      // hyc
      const { Cesium, viewer } = cesiumInstance;
      var position = viewer.camera.positionCartographic;
      viewer.camera.moveBackward(position.height * 0.5);

      viewer.scene.globe.depthTestAgainstTerrain = false;
      //可以选择flyto方法，飞行更流
    },
    onReadyPromise(tileset) {
      const cartographic = Cesium.Cartographic.fromCartesian(
        tileset.boundingSphere.center
      );
      const surface = Cesium.Cartesian3.fromRadians(
        cartographic.longitude,
        cartographic.latitude,
        cartographic.height
      );
      const offset = Cesium.Cartesian3.fromRadians(
        cartographic.longitude - 0.000009,
        cartographic.latitude + 0.000004,
        0
      );
      const translation = Cesium.Cartesian3.subtract(
        offset,
        surface,
        new Cesium.Cartesian3()
      );
      tileset.modelMatrix = Cesium.Matrix4.fromTranslation(translation);
      // viewer.zoomTo(tileset)
    },

    onClicked(e) {
      let cartographic = Cesium.Cartographic.fromCartesian(e.surfacePosition);
      console.log(
        "lng:",
        Cesium.Math.toDegrees(cartographic.longitude),
        ",lat:",
        Cesium.Math.toDegrees(cartographic.latitude)
      );
    },
    getHospitals() {
      // hyc
      let that = this;
      that.hospitalBillboards = [];
      getAllNearHospitalPositionByEarthquakeId({
        earthquakeId: that.selectedEarthquakeInfo.earthquakeId,
      }).then((res) => {
        this.hospitalList = res.data;
        for (let i = 0; i < res.data.length; i++) {
          let hospitalBillboard = {};
          hospitalBillboard.position = {
            lng: res.data[i].lon,
            lat: res.data[i].lat,
          };
        }
      });
    },
    getFireCenters() {
      this.fireCenterBillboards = [];
      let that = this;
      // this.onChange();
      this.fireCenterLoading = true;
      //let sum = 0;
      for (let i = 0; i < this.rescue.length; i++) {
        console.log(that.fireCenterBillboards, "zhimayou002");
        if (i >= that.rescueForceDistribution) {
          break;
        }
        let billboard = {};
        billboard.position = {
          lng: this.rescue[i].fireLon,
          lat: this.rescue[i].fireLat,
          name: this.rescue[i].fireCenterName,
          id: this.rescue[i].fireCenterId,
          address: this.rescue[i].fireCenterAddress,
        };
        billboard.billboard = {
          image: "/img/rescue.png",
          scale: 0.1,
          pixelOffset: {
            x: 0,
            y: -45,
          },
        };
        that.fireCenterBillboards.push(billboard);
      }
      console.log(that.fireCenterBillboards, "098");
      console.log(this.rescue, 666); //然后在这里本来应该能收到数据，但是这边收不到，然后我刚还发现个问题就是
      for (let i = 0; i < that.fireCenterBillboards.length; i++) {
        that.fireCenterBillboards[i].label = {
          text: "" + that.fireCenterBillboards[i].position.name + "",
        };
        that.fireCenterBillboards[i].id =
          "fireCenter_" + 1 + "_" + that.fireCenterBillboards[i].position.id;
      }
      that.fireCenterLoading = false;
      that.layerControl.visible = false;
      that.layerControl.showFireCenter = true;
      that.linedataVisible = false;
      // that.$message.success("救援数据显示完成");
    },
    //医院
    /*    onHospitalReady({ cesiumObject }) {
      window.cesiumObject = cesiumObject;

      //开启聚合功能
      cesiumObject.clustering.enabled = true;
      cesiumObject.clustering.pixelRange = 30;
      cesiumObject.clustering.minimumClusterSize = 3;
    }, */
    /*  onHospitalClusterEvent(clusteredEntities, cluster) {
      cluster.billboard.show = !0;
      cluster.label.show = !1;
      cluster.billboard.id = cluster.label.id;
      cluster.billboard.verticalOrigin = Cesium.VerticalOrigin.CENTER;
      clusteredEntities.length >= 300
        ? (cluster.billboard.image =
            "https://file.zouran.top/assets/cesium/img/clusters/300+.png")
        : clusteredEntities.length >= 150
        ? (cluster.billboard.image =
            "https://file.zouran.top/assets/cesium/img/clusters/150+.png")
        : clusteredEntities.length >= 90
        ? (cluster.billboard.image =
            "https://file.zouran.top/assets/cesium/img/clusters/90+.png")
        : clusteredEntities.length >= 30
        ? (cluster.billboard.image =
            "https://file.zouran.top/assets/cesium/img/clusters/30+.png")
        : clusteredEntities.length >= 10
        ? (cluster.billboard.image =
            "https://file.zouran.top/assets/cesium/img/clusters/10+.png")
        : (cluster.billboard.image =
            "https://file.zouran.top/assets/cesium/img/clusters/" +
            clusteredEntities.length +
            ".png");
    }, */
    onFireCenterReady({ cesiumObject }) {
      window.cesiumObject = cesiumObject;

      //开启聚合功能
      cesiumObject.clustering.enabled = true;
      cesiumObject.clustering.pixelRange = 30;
      cesiumObject.clustering.minimumClusterSize = 2;
    },
    onFireCenterClusterEvent(clusteredEntities, cluster) {
      cluster.billboard.show = !0;
      cluster.label.show = !1;
      cluster.billboard.id = cluster.label.id;
      cluster.billboard.verticalOrigin = Cesium.VerticalOrigin.CENTER;
      //   console.log(clusteredEntities,28);
      clusteredEntities.length >= 300
        ? (cluster.billboard.image =
            "https://file.peteralbus.com/assets/cesium/img/clusters/300+.png")
        : clusteredEntities.length >= 150
        ? (cluster.billboard.image =
            "https://file.peteralbus.com/assets/cesium/img/clusters/150+.png")
        : clusteredEntities.length >= 90
        ? (cluster.billboard.image =
            "https://file.peteralbus.com/assets/cesium/img/clusters/90+.png")
        : clusteredEntities.length >= 30
        ? (cluster.billboard.image =
            "https://file.peteralbus.com/assets/cesium/img/clusters/30+.png")
        : clusteredEntities.length > 10
        ? (cluster.billboard.image =
            "https://file.peteralbus.com/assets/cesium/img/clusters/10+.png")
        : (cluster.billboard.image =
            "https://file.peteralbus.com/assets/cesium/img/clusters/" +
            clusteredEntities.length +
            ".png");
    },
    //物资分配特效
    onMouseover(e) {
      if (e.cesiumObject instanceof Cesium.Label) {
        this.scale = 1.5; // or e.cesiumObject.scale = 1.5
        e.pickedFeature.primitive.scale = 1.5;
      } else if (e.cesiumObject instanceof Cesium.LabelCollection) {
        e.pickedFeature.primitive.scale = 1.5;
      }
    },

    onMouseout(e) {
      if (e.cesiumObject instanceof Cesium.Label) {
        this.scale = 1; // or e.cesiumObject.scale = 1
      } else if (e.cesiumObject instanceof Cesium.LabelCollection) {
        e.pickedFeature.primitive.scale = 1;
      }
    },

    //量算工具
    drawEvt(e, viewer) {
      console.log(e);
      if (e.finished) {
        this.drawing = false;
        if (e.type === "move") {
          viewer.canvas.setAttribute(
            "style",
            `cursor: ${this.restoreCursorMove}`
          );
        }
      } else {
        this.drawing = true;
        if (e.type === "move") {
          viewer.canvas.setAttribute("style", "cursor: move");
        }
        if (e.type === "new") {
          viewer.canvas.setAttribute("style", "cursor: crosshair");
        }
      }
    },
    measureEvt(e, viewer) {
      console.log(e);
      if (e.finished) {
        this.drawing = false;
        if (e.type === "move") {
          viewer.canvas.setAttribute(
            "style",
            `cursor: ${this.restoreCursorMove}`
          );
        }
      } else {
        this.drawing = true;
        if (e.type === "move") {
          viewer.canvas.setAttribute("style", "cursor: move");
        }
        if (e.type === "new") {
          viewer.canvas.setAttribute("style", "cursor: crosshair");
        }
      }
    },
    activeEvt(e, viewer) {
      console.log(e);
      viewer.canvas.setAttribute(
        "style",
        `cursor: ${e.isActive ? "crosshair" : "auto"}`
      );
      if (!e.isActive) {
        this.drawing = false;
        this.restoreCursorMove = "auto";
      }
    },
    editorEvt(e, viewer) {
      console.log(e);
      if (e.type === "move") {
        viewer.canvas.setAttribute("style", "cursor: move");
        this.drawing = true;
      }
    },
    mouseEvt(e, viewer) {
      console.log(e);
      const restoreCursor = getComputedStyle(viewer.canvas).cursor;
      if (!this.drawing) {
        if (e.type === "onmouseover") {
          this.restoreCursorMove = restoreCursor;
          viewer.canvas.setAttribute("style", "cursor: pointer");
        } else {
          viewer.canvas.setAttribute(
            "style",
            `cursor: ${this.restoreCursorMove || "auto"}`
          );
        }
      }
    },
  },
};
</script>

<style scoped>
.toolbar {
  position: absolute;
  left: 1%;
  top: 1%;
  /*min-width: 185px;*/
  z-index: 100;
  color: #fff !important;
  background-color: rgba(0, 0, 0, 0.2);
}

.el-radio {
  margin-right: 0;
}

.el-checkbox {
  margin-right: 0;
}
.upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 10px 10px; /* 设置上下内边距为20px，左右内边距为0 */
}
.toolbar-item {
  margin: 5px;
}

.viewer {
  width: 100%;
  height: 100vh;
}

.buttonClass {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  width: 58%;
  text-align: center;
  z-index: 5;
}
</style>

<style lang="scss" scoped>
::v-deep .bg-info,
::v-deep .vc-location-other-controls .vc-distance-legend,
::v-deep .vc-location-other-controls .vc-status-bar {
  background: #00375e !important;
}

::v-deep .vc-btn--fab {
  padding: 0;
  min-height: 40px !important;
  min-width: 40px !important;
  height: 40px;
  width: 40px;
}

::v-deep .absolute-top-right {
  margin: 0 !important;
  top: 5%;
}
::v-deep .el-button--primary.is-plain {
  background: transparent;
}
// ::v-deep .el-overlay {
//   position: absolute !important;
//   top: 0;
//   left: 0;
//   height: 100px !important;
// }
// ::v-deep .el-overlay-dialog {
//   height: 100px !important;
// }
</style>
